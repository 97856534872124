import Axios from "axios";
import Modal from "modal-enhanced-react-native-web";
import moment from "moment";
import React from "react";
import { FaSearch } from "react-icons/fa";
import Xarrow from "react-xarrows";
import { LoadingCenter } from "../../Components";
import { capitalizeEveryWord, hackHttps } from "../../Helpers";
import { Map } from "../Map";
import "./trace.css";

const SERVER_URL = process.env.REACT_APP_BASE_URL;

const S_DIV = { border: 0, paddingVertical: 10, borderRadius: 10 };
class Trace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      json: false,
      visibleModal: "POPUP_NODE",
      search: "",
      errMsg: "",
    };
  }

  async refreshDataTraceCode(traceCode, inventoryId) {
    const domain = SERVER_URL;

    let url = domain + "/inventory/fullhistory?traceCode=" + traceCode;
    if (inventoryId) url = domain + "/inventory/fullhistory?id=" + inventoryId;

    const opt = {
      method: "get",
      url: url,
    };

    try {
      const response = await Axios(opt);
      const json = response.data;
      let points = [
        {
          plottingPoint: {
            coordinates: [Number(json?.longitude), Number(json?.latitude)],
          },
        },
      ];
      this.setState({ plottingPoints: points });
      this.setState({ show: "busy" });
      setTimeout(() => {
        this.setState({
          show: "trace",
          json,
        });
      }, 1000);
    } catch (err) {
      if (err.response?.data?.message === "inventory not found") {
        this.setState({
          errMsg: "Produk tidak ditemukan",
        });
      }
      this.setState({
        show: "",
        json: false,
      });
    }
  }

  async componentDidMount() {
    // https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
    const url_string = window.location.href;
    const urlGet = new URL(url_string);
    const inventoryId = urlGet.searchParams.get("inventoryId");
    const traceCode = urlGet.searchParams.get("productId");

    if (traceCode || inventoryId) {
      await this.refreshDataTraceCode(traceCode, inventoryId);
    }
  }

  renderCard(img1url, img2url, userName, itemName, onClick, type, otherData) {
    const img1 = hackHttps(img1url);
    const img2 = hackHttps(img2url);

    return (
      <div className="small-card" onClick={onClick}>
        <div className="card-content">
          <img className="card-image" src={img2} alt="Card" />
          <div className="card-details">
            <span className="card-type">{capitalizeEveryWord(type)}</span>
            <p className="card-title">{itemName}</p>
            <div className="card-info">
              <span>
                {otherData?.initialAmount} {otherData?.unit ?? "Kg"}
              </span>
            </div>
            <div className="user-info">
              {img1 ? (
                <img className="user-avatar" src={img1} alt="User" />
              ) : (
                <img
                  className="user-avatar"
                  src={require("../../Assets/Images/pak-tani.png")}
                  alt="User"
                />
              )}

              <div className="user-details">
                <p className="user-name">{userName}</p>
                <p className="user-time">
                  {moment(otherData?.createdAt).format("DD MMM YYYY, HH:MM")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTransferObj(obj, data) {
    const item = data.item;
    const idName1 = "A-T-" + obj.id;
    const idName2 = "IID-T-" + obj.id;
    const userPhoto = obj.toUser && obj.toUser.photo ? obj.toUser.photo : "";
    const itemPhoto = item ? item.linkFoto : "";
    const userName = obj.toUser && obj.toUser.name ? obj.toUser.name : "";
    const itemName = item ? item.nameItem : "";

    return (
      <div>
        <div className="transfer-section">
          <div className="transfer-divider" id={idName1}></div>
        </div>
        <div
          // className="render-transfer-wrap"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={S_DIV} id={idName2}>
            {this.renderCard(
              userPhoto,
              itemPhoto,
              userName,
              itemName,
              () =>
                this.handleClickTransfer(
                  data,
                  obj.id,
                  "Buy",
                  "POPUP_DATA_TRANSFER"
                ),
              "Buy",
              data
            )}
          </div>
        </div>
      </div>
    );
  }

  renderData(data, focusUser, transferId) {
    const itemName = data.item ? data.item.nameItem : "-";
    let url = data.item ? data.item.linkFoto : "";
    let photo = data.creator ? data.creator.photo : "";
    let latCoord = data.latitude ? Number(data.latitude) : 0;
    let longCoord = data.longitude ? Number(data.longitude) : 0;
    let userName = data.creator ? data.creator.name : "-";
    let userCity = data.creator ? data?.creator?.city?.toUpperCase() : "-";

    if (!focusUser && data.transferHistory && data.transferHistory.length > 0) {
      focusUser = true;
    }

    if (transferId >= 0) {
      for (let i = 0; i < data.transferHistory.length; i++) {
        if (data.transferHistory[i].id === transferId) {
          const toUser = data.transferHistory[i].toUser;
          photo = toUser.photo ? toUser.photo : "";
          latCoord = toUser.latitude ? Number(toUser.latitude) : 0;
          longCoord = toUser.longitude ? Number(toUser.longitude) : 0;
          userName = toUser.name ? toUser.name : "";
          userCity = toUser.city ? toUser.city : "";
          break;
        }
      }
    }

    photo = hackHttps(photo);
    url = hackHttps(url);

    return (
      <div className="data-container">
        <div className="data-card">
          <div className="data-content">
            <img className="data-image" src={url} alt="Item" />
            <div className="data-details">
              <p className="data-name">{itemName}</p>
              <span className="data-info">
                {data.initialAmount} {data?.unit}
              </span>
            </div>
          </div>
          <div>
            <span className="data-type">{this.state?.type}</span>
          </div>
          <div className="data-user-info">
            {photo ? (
              <img className="data-user-image" src={photo} alt="User" />
            ) : (
              <img
                className="user-avatar"
                src={require("../../Assets/Images/pak-tani.png")}
                alt="User"
              />
            )}

            <div className="data-user-details">
              <p className="data-user-name">{userName}</p>
              <p className="data-user-time">
                {moment(data?.createdAt).format("DD MMM YYYY, HH:MM")}
              </p>
            </div>
          </div>
          <div className="data-location-info">
            <img
              className="data-location-icon"
              src={require("../../Assets/Images/location.png")}
              alt="Location"
            />
            <p className="data-location-text">{userCity}</p>
          </div>
          {this.state.selectedData?.longitude &&
          this.state.selectedData?.latitude ? (
            <div className="data-button-wrap">
              <button
                className="data-custom-button"
                onClick={() => this.setState({ visibleModal: "POPUP_MAP" })}
              >
                <span className="data-custom-button-text">
                  Lihat Pin Lokasi
                </span>
              </button>
            </div>
          ) : (
            <div className="data-margin-bottom" />
          )}
        </div>
      </div>
    );
  }

  handleClickTransfer(data, transferId, type, modalType) {
    if ((data?.latitude, data?.longitude)) {
      let points = [
        {
          plottingPoint: {
            coordinates: [Number(data?.longitude), Number(data?.latitude)],
          },
        },
      ];
      this.setState({ plottingPoints: points });
    }
    this.setState({
      visibleModal: modalType,
      selectedDataTransfer: data,
      selectedData: data,
      selectedTransferId: transferId,
      type: type,
      center: {
        lat: Number(data?.latitude),
        lng: Number(data?.longitude),
      },
    });
  }

  handleClickIconMap() {
    this.setState({
      visibleModal: "POPUP_MAP",
    });
  }

  renderSimpleData(data, title) {
    const itemName = data.item ? data.item.nameItem : "-";
    const inventoryId = data.id;
    const idName = "IID" + inventoryId + "";
    const itemPhoto = data.item ? data.item.linkFoto : "";
    const userPhoto = data.creator ? data.creator.photo : "";
    const userName = data.creator ? data.creator.name : "-";

    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div style={S_DIV} id={idName}>
          {this.renderCard(
            userPhoto,
            itemPhoto,
            userName,
            itemName,
            () =>
              this.handleClickTransfer(
                data,
                "",
                title ?? "Create Stock",
                "POPUP_DATA"
              ),
            title ?? "Create Stock",
            data
          )}
        </div>
      </div>
    );
  }

  renderContent(child) {
    const parents = child.parent ? child.parent : [];
    let parentMethod = null;
    let parentArrow = null;
    const iidA = "A" + child.id;
    const iid = "IID" + child.id + "";

    if (child && child.parentWeight.length > 0 && parents.length > 0) {
      let cm = child.parentWeight[0].createMethod;
      if (cm === "SPLIT" || cm === "BUY") {
        const num =
          child.siblings && child.siblings.length > 0
            ? 1 + child.siblings.length
            : 1;
        cm = "SPLIT (1/" + num + ")";
      }

      parentMethod = (
        <div className="parent-container">
          <div style={S_DIV} id={iidA}></div>
        </div>
      );

      parentArrow = (
        <Xarrow
          color={"black"}
          strokeWidth={2}
          start={iidA}
          end={iid}
          startAnchor="bottom"
          endAnchor={{ position: "top", radius: 10 }}
          path="grid"
        />
      );
    }

    const transferHistory = child.transferHistory ? child.transferHistory : [];
    let tfView = null;
    if (transferHistory.length > 0) {
      const arr = [];
      for (let i = 0; i < transferHistory.length; i++) {
        let start;
        let mid;
        let end;

        if (i == 0) {
          start = iid;
        } else {
          start = "IID-T-" + transferHistory[i - 1].id;
        }
        mid = "A-T-" + transferHistory[i].id;
        end = "IID-T-" + transferHistory[i].id;

        arr.push({ start, mid, end });
      }
      tfView = (
        <div>
          {transferHistory.map((obj) => {
            return this.renderTransferObj(obj, child);
          })}
          {arr.map((obj) => {
            return (
              <>
                <Xarrow
                  color={"black"}
                  strokeWidth={2}
                  start={obj.start}
                  end={obj.end}
                  startAnchor="bottom"
                  endAnchor={{ position: "top", radius: 10 }}
                  path="grid"
                />
                {/* <Xarrow
                  color={"black"}
                  strokeWidth={2}
                  start={obj.mid}
                  end={obj.end}
                  startAnchor="bottom"
                  endAnchor={{ position: "top", radius: 10 }}
                  path="grid"
                /> */}
              </>
            );
          })}
        </div>
      );
    }

    return (
      <div
        style={{
          backgroundColor: "#f7f7f7",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {parents.map((obj) => this.renderContent(obj))}
        </div>
        {parentMethod}
        {this.renderSimpleData(child, child?.parentWeight?.[0]?.createMethod)}
        {parents.map((obj) => {
          let start = "IID" + obj.id + "";
          if (obj.transferHistory.length > 0) {
            const lastIndex = obj.transferHistory.length - 1;
            start = "IID-T-" + obj.transferHistory[lastIndex].id;
          }
          return (
            <Xarrow
              color={"black"}
              strokeWidth={2}
              start={start}
              end={iidA}
              startAnchor="bottom"
              endAnchor={{ position: "top", borderRadius: 10 }}
              path="grid"
              curveness={100}
              showHead={false}
            />
          );
        })}
        {tfView}
        {parentArrow}
      </div>
    );
  }

  renderError() {
    return (
      <div className="flex-container">
        <span>PLEASE RETRY LATER</span>
      </div>
    );
  }

  renderBusy() {
    return (
      <div className="flex-container">
        <LoadingCenter />
      </div>
    );
  }

  updateSearch = async (search) => {
    this.setState({ search });
    if (search.length == 36) {
      await this.refreshDataTraceCode(search, null);
    }
  };

  renderModal() {
    let selectedData = null;
    if (this.state.selectedData)
      selectedData = this.renderData(this.state.selectedData);
    let selectedDataTransfer = null;
    if (this.state.selectedDataTransfer)
      selectedDataTransfer = this.renderData(
        this.state.selectedDataTransfer,
        true,
        this.state.selectedTransferId
      );

    return (
      <>
        <Modal
          isVisible={this.state.visibleModal === "POPUP_DATA"}
          onBackdropPress={() => this.setState({ visibleModal: null })}
        >
          <div style={{ width: "100%" }}>{selectedData}</div>
        </Modal>
        <Modal
          isVisible={this.state.visibleModal === "POPUP_DATA_TRANSFER"}
          onBackdropPress={() => this.setState({ visibleModal: null })}
        >
          <div style={{ width: "100%" }}>{selectedDataTransfer}</div>
        </Modal>
        {this.state?.selectedData?.latitude &&
        this.state?.selectedData?.longitude ? (
          <Modal
            isVisible={this.state.visibleModal === "POPUP_MAP"}
            onBackdropPress={() => this.setState({ visibleModal: null })}
          >
            <div style={{ height: "70vh", backgroundColor: "white" }}>
              <Map
                center={this.state.center}
                data={this.state.plottingPoints}
              />
            </div>
          </Modal>
        ) : null}
      </>
    );
  }

  renderTrace() {
    return (
      <div className="container-home">
        {this.renderModal()}
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div className="transaction-container">
            <span className="transaction-text">
              Cerita lengkap dari produk{" "}
              {this.state?.json?.item?.nameItem?.toUpperCase()}
            </span>
          </div>
          <div
            style={{
              padding: 20,
            }}
          >
            <div>
              <div
                style={{
                  minWidth: this.state.contentWidth,
                  paddingVertical: 10,
                }}
              >
                {this.renderContent(this?.state?.json, true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHome() {
    const { search } = this.state;
    const LOGO_URL =
      "https://kelud.pasarmikro.id:9443/api/v1/publicfile/p-b1a9a9e9319d4ef010e9c966086a6e18.png";

    return (
      <div className="container">
        <div className="content">
          <div className="title">
            <span className="logo-text">CERITAPRODUK.ID</span>
          </div>
          <div className="image-container">
            <img className="logo-image" src={LOGO_URL} alt="Logo" />
          </div>
          <div className="subtitle">
            <span className="subtitle-text">
              Masukkan kode produk untuk diceritakan oleh kami
            </span>
          </div>

          <div className="input-container">
            <input
              className="input"
              placeholder="Masukkan kode produk"
              onChange={(e) => this.updateSearch(e.target.value)}
            />
            {search && <FaSearch className="search-icon" />}
          </div>
          {this.state.errMsg ? (
            <div>
              <span style={{ color: "red" }}>{this.state.errMsg}</span>
            </div>
          ) : null}
        </div>
        <div className="footer">
          <p>
            <a href="http://ceritaproduk.id">ceritaproduk.id</a> - PT. Pasar
            Mikro Global &copy; 2024
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.show === "trace") return this.renderTrace();
    if (this.state.show === "busy") return this.renderBusy();
    if (this.state.show === "error") return this.renderError();
    return this.renderHome();
  }
}

export default Trace;
