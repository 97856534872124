import React from "react";
import Trace from "./Views/Trace/Trace";

export default function AppV2() {
  return (
    <div>
      <Trace />
    </div>
  );
}
