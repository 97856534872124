import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { filterLatLong } from "../../Helpers";

const OPTIONS = {
  minZoom: 4,
  maxZoom: 20,
};

function Map({ data, center }) {
  const [map, setMap] = useState(null);

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const googleMapsId = process.env.REACT_APP_GOOGLE_MAPS_ID;

  const { isLoaded } = useJsApiLoader({
    id: googleMapsId,
    googleMapsApiKey: googleMapsApiKey,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const formattedCoordinates = filterLatLong(data);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      if (formattedCoordinates?.length > 0) {
        formattedCoordinates.forEach((coord) => bounds.extend(coord));
      }
      map.fitBounds(bounds);
      setMap(map);
    },
    [formattedCoordinates]
  );

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map && center) {
      const bounds = new window.google.maps.LatLngBounds(center);
      formattedCoordinates.forEach((coord) => bounds.extend(coord));
      map.fitBounds(bounds);
    }
  }, [map, center, formattedCoordinates]);

  return isLoaded ? (
    <GoogleMap
      options={OPTIONS}
      mapContainerStyle={containerStyle}
      zoom={2}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Polygon
        path={formattedCoordinates}
        options={{
          strokeColor: "#006386",
          fillColor: "#7ECBE5",
          strokeWeight: 5,
        }}
      />
      {data?.length === 1 ? (
        <Marker
          position={{
            lat: data[0].plottingPoint.coordinates[1],
            lng: data[0].plottingPoint.coordinates[0],
          }}
        />
      ) : null}
    </GoogleMap>
  ) : null;
}

export default Map;
