import React from "react";
import { FiLoader } from "react-icons/fi";

export default function LoadingCenter() {
  return (
    <div
      style={{
        opacity: 1,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <FiLoader
        style={{
          fontSize: "40px",
          animation: "spin 1s linear infinite",
        }}
      />
      <p
        style={{
          margin: "10px 0 0 0",
          fontFamily: "sans-serif",
          fontWeight: "bold",
        }}
      >
        Loading
      </p>
    </div>
  );
}
