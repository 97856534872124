import React from "react";
import { View, Dimensions, Text } from "react-native";
import { Trace } from "./Views/Trace";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "busy",
      landscape: false,
      w: Dimensions.get("window").width,
    };
  }

  async componentDidMount() {
    const ww = Dimensions.get("window").width;
    const wh = Dimensions.get("window").height;
    if (ww > wh) {
      this.setState({ landscape: true, w: wh });
    }
  }

  componentWillMount() {}

  render() {
    return (
      <>
        <View style={{ flex: 1 }}>
          <View style={{ height: "100vh" }}>
            <Trace />
          </View>
        </View>
      </>
    );
  }
}

export default App;
